<template>
  <div class="login">
    <h1 class="title">Datos cliente</h1>
    <form action class="form" @submit.prevent="save">      
      <div class="field">
        <label class="label" for="#id_remu">Código REMU</label>
        <div class="control">
          <input
            v-model="record.id_remu"
            class="input"
            type="text"
            id="id_remu"
            placeholder="Codigo REMU"
          >
        </div>
      </div>
      <div class="field">
        <label class="label" for="#id_conta">Código Conta</label>
        <div class="control">
          <input
            v-model="record.id_conta"
            class="input"
            type="text"
            id="id_conta"
            placeholder="Codigo Conta"
          >
        </div>
      </div>
      <div class="field">
        <label class="label" for="#start_activities">Inicio Actividad</label>
        <div class="control">
          <input
            v-model="record.start_activities"
            class="input"
            type="date"
            id="start_activities"
          >
        </div>
      </div>
      <div class="field">
        <label class="label" for="#taxRegime">Régimen Tributario</label>
        <div class="control">
          <select v-model="record.tax_regime_id" id="taxRegime">
            <option v-for="taxRegime in taxRegimes" v-bind:value="taxRegime.id" :key="taxRegime.id">{{ taxRegime.name }}</option>
          </select>
        </div>
      </div>
      <div class="field">
        <label class="label" for="#billyType">Tipo Facturador</label>
        <div class="control">
          <select v-model="record.biller_type_id" id="billyType">
            <option v-for="billerType in billerTypes" v-bind:value="billerType.id" :key="billerType.id">{{ billerType.name }}</option>
          </select>
        </div>
      </div>
      <div class="field">
        <label class="label" for="#organizational_type">Tipo Organizacional</label>
        <div class="control">
          <input
            v-model="record.organizational_type"
            class="input"
            type="text"
            id="organizational_type"
            placeholder="Tipo Organizacional"
          >
        </div>
      </div>
      <p v-if="error" class="error">{{ this.strError }}</p>
      <input class="form-submit" @click.prevent="save" type="submit" value="Grabar">
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    record: {
      id_remu: '', 
      id_conta: '', 
      tax_regime_id: -1, 
      biller_type_id: -1,
      organizational_type: ''
    },
    addresses: [],
    partners: [],
    error: false,
    strError: '',
    tmpAddress: { name: '', address: '' },
    tmpPartner: { rut: '', name: '', participation_perc: 100, birthday: ''}
  }),
  props: {
      editRecord: Object,
      taxRegimes: Array,
      billerTypes: Array,
      company: Object,
      recordProp: Object
  },
  methods: {
		save () {
      this.record.company_id = this.company.id
      console.log(this.record)
      const action = this.record.id? 'edit':'create'
			this.$axios
			.post('company/record/' + action , { record: this.record })
			.then(response => {
        console.log(response.data)
        this.$router.push({ name: "CompanyShow", params: { id: this.company.id }})
			})
			.catch(error => console.log(error))
		}
  },
  watch: {
    recordProp: function(newVal) {
      this.record = newVal
      this.record.start_activities = new Date(this.record.start_activities).toISOString().replaceAll('T00:00:00.000Z', '')
      // console.log(this.record.start_activities.toISOString())
      // this.record.start_activities = this.record.start_activities.getFullYear() + '/' + this.record.start_activities.getMonth() + 1 + '/' + this.record.start_activities.getDay()
    }
  },
	computed: {
		fullRut: {
			get: function() {
				// return this.company.rut + '-' + this.company.dv
        // console.log(this.company.rut)
        // console.log(this.company.rut.length > 0 ? this.company.rut.replaceAll('-', '') + '-' + this.company.dv : '')
        return this.company.rut.length > 0 ? this.company.rut + '-' + this.company.dv.replaceAll('-', '') : ''
			},
			set: function (newValue) {
				const local_rut = newValue.replaceAll('.', '').replaceAll('-', '').slice(0, -1)
				const local_dv = newValue.slice(-1).replaceAll('-', '')
				// console.log('rut: ' + local_rut)
				// console.log('dv: ' + local_dv)
				this.company.rut = local_rut
				this.company.dv = local_dv
			}
		}
	}
}
</script>
